@import url('https://fonts.googleapis.com/css?family=Barlow');

.App-logo {
  height: 40vmin;
  pointer-events: none;
  margin: auto;
  margin-left: 40px;
}

.grid-container {
  justify-content: center;
}

.no-results-text {
  text-align: center;
  line-height: 30px;
  font-weight: bold;
  font-size: 150%;
}

.top-space {
  margin-top: 100px;
}

.card {
  position: relative;
  border-radius: 8px;
  padding: 0px 0 20px;
  animation: 0.5s cubic-bezier(0, 0, 0.25, 1) 0.4s in both;
}

@media (min-width: 576px) {
  .card {
    padding-top: 0px;
  }
}

@media (min-width: 992px) {
  .card-wrapper {
    max-width: 648px !important;
    padding: 0 !important;
  }

  .card {
    background: #fff;
    padding: 0px 80px 0px;
    margin: 72px auto 0;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
